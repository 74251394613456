.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.it-header-slim-wrapper {
  background: #1d3666 !important  ;
  padding: 6.5px 18px;
}

.linkMenu {
  margin-right: 2em;
  color: white;
}

.form-control[readonly] {
  background: white;
  cursor: default;
}

.collapse-header [data-toggle="collapse"] {
  color: #06c;
}

.react-select-container {
  background-color: "red";
}

.react-select__menu {
  height: 100vh;
}

.dropdown-menu .inner {
  display: block;
}

.dropdownMenuLink {
  background: rgb(46, 88, 166);
}

.stemmaComune {
  width: 50px;
  height: 60px;
}

.switchComune {
  color: white;
  background: none;
  border: none;
}

.textComune {
  font-size: 25px !important;
  margin-bottom: 0 !important;
  font-weight: 600;
  letter-spacing: unset !important;
  line-height: 1.1 !important;
  background: red !important;
}

.storeApp {
  width: 150px;
}

.HeroHome {
  font-size: 23px;
  color: white;
}

.HeroHomeText {
  color: white;
  margin-top: 15px;
  font-size: 15px;
}

.btnHome {
  margin-top: 5px;
}

.btn-primary {
  background-color: #2e58a6 !important;
  border-color: #080e1a !important;
}

.it-header-center-wrapper {
  background: #2e58a6 !important;
}

.it-header-navbar-wrapper {
  background: #2e58a6 !important;
}

@media only screen and (max-width: 1000px) {
  .entityComune {
    font-size: 1em;
  }

  .it-header-slim-wrapper {
    background: #004080;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.trasparente {
  z-index: 400;
  position: fixed;
  background: aliceblue;
  width: 100%;
  height: 100%;
  background-color: #ffffff1e;
  top: 0;
  left: 0;
}

.spinner {
  top: 40%;
  position: fixed;
  left: 48%;
  z-index: 401;
}




@media screen and (max-width: 991px) {
  .spinner {
    top: 50%;
    position: fixed;
    left: 43%;
    z-index: 401;
  }
}



.main-body {
  min-height: 615px;
}

.pagamentiDovuti {
  background: whitesmoke;
  padding: 1%;
  max-height: 350px;
  overflow-y: auto;
}

#dropdownEntity {
  color: white;
}

#dropdownEntity:hover {
  color: white;
}

.it-header-slim-wrapper {
  background: #1d3666 !important;
  padding: 0px;
}

.btn-full {
  margin-top: 0;
  margin-bottom: 0;
}

/* CHAT CSS */

.chat-button {
  color: white;
  font-size: 22px;
  font-weight: 900;
  position: fixed;
  bottom: 3%;
  right: 20px;
  left: auto;
  height: 55px;
  width: 55px;
  background: #2d2d2d;
  border: 0;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  padding: 2px;
  transition: all 0.4s, bottom 0.8s ease-in-out;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  border-radius: 60%;
  background-color: #049ad8;
  animation-name: loadTab;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
}
.chat-box {
  background: #fff;
  position: fixed;
  bottom: 3%;
  right: 0;
  top: 0;
  color: #000;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  z-index: 2147483646;
  zoom: 0;
  transition: 0.3s ease-in-out;
  -webkit-font-smoothing: antialiased;
  width: 350px;
  height: 450px;
  border-radius: 10px 10px 0 0;
  top: inherit;
  transition: all 0.6s ease-in-out, width 0.2s ease-in-out, top 0.4s ease-in-out;
  right: 20px;

  opacity: 100;
}
.chat-top-bar {
  background-color: #049ad8;
  color: #333333;
  border: none;
  font-weight: 900;
  padding: 15px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}
.chat-top-bar button {
  background: transparent;
  border: none;
  cursor: pointer;
}
.chat-top-bar button svg {
  width: 15px;
  fill: #333333;
}
.chat-hidden {
  opacity: 0;
  bottom: -100%;
}
@-moz-keyframes loadTab {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes loadTab {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-o-keyframes loadTab {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes loadTab {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#chatframe {
  height: 400px;
  width: 350px;
}

@media only screen and (max-width: 600px) {
  #chatframe {
    height: 500px;
    width: 350px;
  }
  .chat-box {
    background: #fff;
    position: fixed;
    bottom: 15%;
    right: 0;
    top: 0;
    color: #000;
    font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    z-index: 2147483646;
    zoom: 0;
    transition: 0.3s ease-in-out;
    -webkit-font-smoothing: antialiased;
    width: 350px;
    height: 450px;
    border-radius: 10px 10px 0 0;
    top: inherit;
    transition: all 0.6s ease-in-out, width 0.2s ease-in-out,
      top 0.4s ease-in-out;
    right: 20px;

    opacity: 100;
  }

  .chat-hidden {
    opacity: 0;
    bottom: -100%;
  }
}

.form-group {
  position: relative;
  margin-bottom: 1rem;
  margin-top: 0;
}

.cardCustom {
  height: 100%;
}

.collapseCustom {
  border-top: 0px !important;
}

.collapseCustom:focus {
  outline: none;
}

.headerCustom {
  background-color: #2e58a6;
}

.footerCustom {
  background: #1d3666;
}

.hrCustom {
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(46, 88, 166, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.imageCustom {
  width: 50%;
}

@media only screen and (max-width: 800px) {
  .imageCustom {
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .input-padding{
    padding-top: 10px;
  }

  .group-padding {
    margin-top: -14px;
  }
}
